import { FunctionComponent } from 'react';
import TopMenu from '../shared/top-menu/top-menu';
import Footer from '../shared/footer/footer';
import './about.scss';

export type Props = {};

const About: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page about">
      <TopMenu selected="about"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>SurveyTester: Our Team and History</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <p>
            Behind SurveyTester stands a team of passionate professionals, each an expert in their
            field. Our collaborative spirit and commitment to excellence drive us to constantly
            innovate and improve. We pride ourselves on our customer-centric approach, working
            closely with clients to understand their unique challenges and deliver tailored
            solutions.
          </p>
          <p>
            Our team is our greatest asset. Each member brings a wealth of experience and a unique
            perspective, contributing to an environment where creativity thrives. From our
            developers to our customer support specialists, we are committed to delivering
            excellence in every aspect of our service.
          </p>
          <br />
          <h2>Team Members</h2>
          <div className="team-mates">
            <div className="team-mate">
              <img src="/people/bernhard.jpg" alt="Bernhard Witt" />
              <div className="name">Bernhard Witt</div>
              <div className="title">Founder & CEO</div>
            </div>
            <div className="team-mate">
              <img src="/people/laura.jpg" alt="Laura Blacklock" />
              <div className="name">Laura Blacklock</div>
              <div className="title">Business Development (USA)</div>
            </div>
            <div className="team-mate">
              <img src="/people/ester.jpg" alt="Ester Mittermeier" />
              <div className="name">Ester Mittermeier</div>
              <div className="title">Sales & Marketing</div>
            </div>
            <div className="team-mate">
              <img src="/people/jim.jpg" alt="James Sugrue" />
              <div className="name">James Sugrue</div>
              <div className="title">Support & Documentation</div>
            </div>
            <div className="team-mate">
              <img src="/people/todd.jpg" alt="Todd Sandelman" />
              <div className="name">Todd Sandelman</div>
              <div className="title">Development & Support</div>
            </div>
            <div className="team-mate">
              <img src="/people/anne.jpg" alt="Anne Witt" />
              <div className="name">Anne Witt</div>
              <div className="title">Marketing</div>
            </div>

            <div className="team-mate">
              <img src="/people/surveybot.jpg" alt="SurveyBot" style={{ width: 'unset' }} />
              <div className="name">Survey Bot</div>
              <div className="title">QA Professional</div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>The Origin and Evolution of SurveyTester</h2>
          <p>
            SurveyTester was born from a common frustration in survey research. Our founder,
            Bernhard Witt, recognized the inefficiency of the survey QA process, which involved
            countless global email exchanges. This challenge inspired the creation of SurveyTester.
          </p>
          <p>
            Simultaneously, Jim Sugrue and his team were developing a similar solution for a client.
            When Bernhard and Jim discovered their parallel efforts, they chose to collaborate, with
            Bernhard's team leading the development.
          </p>
          <p>
            In its early years, SurveyTester underwent several redevelopments to keep pace with
            evolving technology and user needs. This adaptability has been our hallmark from the
            beginning.
          </p>
          <p>
            Today, SurveyTester is a cutting-edge application built on React, C#, and SQL Server.
            We've evolved beyond our initial versions, adapting to changing security requirements
            and developing new integration methods for various survey systems.
          </p>
          <p>
            Our journey reflects our commitment to innovation and understanding of our clients'
            changing needs. From tackling email overload in survey QA to providing sophisticated,
            automated testing solutions, we've stayed true to our mission of delivering efficient
            and effective survey testing tools.
          </p>
          <p>
            As we look to the future, we carry forward our collaborative spirit and problem-solving
            drive, ready to meet the next challenges in survey testing and quality assurance.
          </p>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Get in Touch</h2>
          <p>
            Ready to transform your survey QA process? Join us on our journey and experience the
            benefits of SurveyTester. Contact us today or start your free trial to see our premium
            features in action.
          </p>
          <div className="free-trial">
            <div
              className="free-trial-button"
              onClick={(e) => {
                //e.preventDefault();
                //history('/freetrial');
              }}
            >
              <a href="/freetrial">
                <span>Free Trial</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default About;
