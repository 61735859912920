import { useState } from 'react';
import { FunctionComponent } from 'react';
import TopMenu from '../shared/top-menu/top-menu';
import Footer from '../shared/footer/footer';
import './pricing.scss';
import Calculator from '../shared/caclulator/calculator';
export type Props = {};

// 1 / 50 / 100
const USDPerProject = ['99.00', '79.00', '69.00'];
const EURPerProject = ['89,00', '74,00', '64,00'];
const GBPPerProject = ['79.00', '63.00', '55.00'];
const USDPrices = ['99.00 ', /*79*/ '3,950.00', /*59*/ '6,900.00'];
const EURPrices = ['89,00', /*74*/ '3.700,00', /*55*/ '6.400,00'];
const GBPPrices = ['79.00', /*63*/ '3,150.00', /*47*/ '5,500.00'];

const Pricing: FunctionComponent<Props> = (props) => {
  const [currency, setCurrency] = useState<'USD' | 'EUR' | 'GBP'>('USD');

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  return (
    <div className="page pricing">
      <TopMenu selected="pricing"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>SurveyTester Pricing</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>At SurveyTester, simplicity is at the core of our pricing structure.</h2>
          <p>
            Our pricing is straightforward and based solely on the number of projects you create.
            There are no limits on the number of users or test runs required for your QA process
            within a project.
          </p>

          <p>
            We offer the following packages. Select your preferred Currency:
            <button
              className={'package-currency' + (currency === 'USD' ? ' selected' : '')}
              onClick={() => {
                setCurrency('USD');
              }}
            >
              USD
            </button>
            <button
              className={'package-currency' + (currency === 'EUR' ? ' selected' : '')}
              onClick={() => {
                setCurrency('EUR');
              }}
            >
              EUR
            </button>
            <button
              className={'package-currency' + (currency === 'GBP' ? ' selected' : '')}
              onClick={() => {
                setCurrency('GBP');
              }}
            >
              GBP
            </button>
          </p>
          <div className="price-boxes">
            <div className="price-box">
              <div className="package-name">Pay as you Go</div>
              <div className="package-description">
                The most flexible option. You pay for projects once you create them.
              </div>
              <div className="package-price">
                {currency === 'USD' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{USDPrices[0]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {USDPerProject[0]} per project
                    </div>
                  </>
                )}
                {currency === 'EUR' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{EURPrices[0]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {EURPerProject[0]} per project
                    </div>
                  </>
                )}
                {currency === 'GBP' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{GBPPrices[0]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {GBPPerProject[0]} per project
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="price-box">
              <div className="package-name">Prepaid 50</div>
              <div className="package-description">
                Load-up your account with 50 projects for a better price per project
              </div>
              <div className="package-price">
                {currency === 'USD' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{USDPrices[1]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {USDPerProject[1]} per project
                    </div>
                  </>
                )}
                {currency === 'EUR' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{EURPrices[1]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {EURPerProject[1]} per project
                    </div>
                  </>
                )}
                {currency === 'GBP' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{GBPPrices[1]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {GBPPerProject[1]} per project
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="price-box">
              <div className="package-name">Prepaid 100</div>
              <div className="package-description">
                Start saving 30% compared to Pay as you Go.{' '}
                <a
                  href="/contact"
                  onClick={(e) => {
                    //e.preventDefault();
                    //history('/contact');
                  }}
                >
                  Contact us for more projects
                </a>
                .
              </div>
              <div className="package-price">
                {currency === 'USD' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{USDPrices[2]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {USDPerProject[2]} per project
                    </div>
                  </>
                )}
                {currency === 'EUR' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{EURPrices[2]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {EURPerProject[2]} per project
                    </div>
                  </>
                )}
                {currency === 'GBP' && (
                  <>
                    <div>
                      <span className="currency">{currency}</span>
                      <span className="price">{GBPPrices[2]}</span>
                    </div>
                    <div className="price-per-project">
                      {currency} {GBPPerProject[2]} per project
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="price-box">
              <div className="package-name">Flatrate</div>
              <div className="package-description">
                Let's minimize accounting expences, and talk about your tailored flatrate.
              </div>
              <div className="package-price">
                <>
                  <div>
                    <span className="price">
                      <a
                        href="/contact"
                        onClick={(e) => {
                          //e.preventDefault();
                          //history('/contact');
                        }}
                      >
                        Contact Us
                      </a>
                    </span>
                  </div>
                  <div className="price-per-project">Fantastic price per project</div>
                </>
              </div>
            </div>
          </div>

          <div className="vat-details">
            All prices are exclusive of VAT. We will add 19% VAT to all customers in Germany and use
            reverse VAT for all customers in other EU countries if a valid VAT ID is provided. No
            VAT is added for all companies outside the European Union.
          </div>
        </div>
      </div>
      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>Definition of a project:</h2>
          <p>
            A project is primarily associated with a single survey link. It's important to note that
            during the QA phase, scriptwriters may send you a new link for the same project. In such
            cases, you can simply update the survey link within the existing SurveyTester project.
            However, it's crucial to avoid mixing links from different projects into the same
            SurveyTester project, as this can lead to data inconsistencies, including errors in Test
            Run information.
          </p>
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <Calculator
            Currency={currency}
            costPerProject={
              currency === 'USD'
                ? USDPerProject[2]
                : currency === 'EUR'
                ? EURPerProject[2]
                : GBPPerProject[2]
            }
          />
        </div>
      </div>

      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>Start your journey to efficient and structured Survey QA today ...</h2>
          <p>
            ... and optimize time and cost for your projects. We offer a free 14-day trial and are
            eager to support you with our expertise throughout the testing period.
          </p>
          <div className="free-trial">
            <div
              className="free-trial-button"
              onClick={(e) => {
                //e.preventDefault();
                //history('/freetrial');
              }}
            >
              <a href="/freetrial">
                <span>Start your SurveyTester free 14-day-trial:</span>
              </a>
            </div>
          </div>
          <br />
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Pricing;
